import React from "react";

import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import IconArticle from "../utils/icons/Article Icon.svg";
import BackIcon from "../utils/icons/Back Icon.svg";
import HomeIcon from "../utils/icons/Home Icon.svg";
import "../css/global.css";
// import slug from "slug"
export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        texts {
          allCollectionsText
          articlesInCollectionZeroText
          articlesInCollectionOneText
          articlesInCollectionTwoText
          articlesInCollectionMultipleText
        }
      }
    }

    allAdminYaml {
      edges {
        node {
          collections {
            name
            description
            id
            slug
          }
        }
      }
    }

    allMarkdownRemark(sort: { fields: frontmatter___sort, order: ASC }) {
      edges {
        node {
          id
          parent {
            id
            ... on File {
              name
              sourceInstanceName
            }
          }
          frontmatter {
            title
            sort
            description
            date(formatString: "DD.MM.yyyy")
          }
        }
      }
    }
  }
`;
function Card(props) {
  return (
    <Link
      to={props.to}
      // slug={props.to}
      style={{ textDecoration: "none", "&:hover": { textDecoration: "none" } }}
    >
      <div className=" z-20 hover:bg-pale-blue bg-white border border-color-border">
        <div className="p-8 flex flex-col ">
          <p className=" sm:text-hxl text-base text-text-violet font-semibold capitalize ">
            {props.title}
          </p>
          <p className="mt-4 text-sm text-text-blue font-normal">
            {props.description}
          </p>
          <p className="mt-4 text-xs text-light-blue font-thin  leading-normal tracking-wide">
            Last updated on {props.date}
          </p>
        </div>
      </div>
    </Link>
  );
}

const collection = ({ data, pageContext }) => {
  const { collectionId } = pageContext;
  const articles = data.allMarkdownRemark.edges;
  const collection = data.allAdminYaml.edges[0].node.collections.filter(
    (item) => item.id === collectionId
  );
  const filtered_articles = articles.filter(
    (item) => item.node.parent.sourceInstanceName === collectionId
  );

  return (
    <Layout>
      <div className="container  md:max-w-4xl sm:max-w-sm md:px-8 lg:px-0 max-w-xs xs:px-4 sm:px-0 mx-auto mt-8">
        <div className="flex flex-row ">
          <Link to="/">
            <img
              className="bg-light-blue p-2 rounded-full mr-2"
              src={HomeIcon}
              alt="Home"
            />
          </Link>
          <div className="bg-btn-blue rounded-custom3 text-white px-4 py-2 text-xs sm:text-sm font-normal capitalize">
            {` ${collection[0].name}`}
          </div>
        </div>
        <div className="bg-color-light-blue bg-opacity-25 border rounded-md sm:p-8 p-4 mt-8">
          <div className="flex flex-row items-start mb-8 ">
            <div className="sm:w-1/12 w-1/6 mt-2">
              <Link to="/">
                <img src={BackIcon} alt="BackLogo" />
              </Link>
            </div>

            <div className="sm:w-11/12 w-5/6">
              <div className=" sm:text-hxl text-base text-text-violet font-semibold capitalize">
                {collection[0].name}
              </div>
              <p className="mt-4 text-sm text-text-blue font-normal leading-tight ">
                {collection[0].description}
              </p>
              <ul className="mt-4 flex flex-row items-center mb-8">
                <img src={IconArticle} alt="IconBook" />
                <p className="px-4 text-xs text-light-blue font-light leading-tight ">
                  {filtered_articles.length} articles in this collection
                </p>
              </ul>
            </div>
          </div>

          {filtered_articles.map((item, index) => {
            const article = item.node.frontmatter;
            const encodedTitle =
              item.node.id.substring(1, 8) +
              `-` +
              article.title.replace(/\s/g, "-");
            return (
              <div key={index}>
                <Card
                  to={`/${item.node.parent.sourceInstanceName}/${encodedTitle}`}
                  title={article.title}
                  description={article.description}
                  date={article.date}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};
export default collection;
